  .scrollToTop {
    position: fixed;
    bottom: 50px;
    right: 8px;
    animation: animateFromRight 1.5s ease-in-out ;
    cursor: pointer;
    color: var(--color);
  }

  .scrollToTop-icon{
   font-size: clamp(40px , 5vw , 60px);
  }
  
  @keyframes animateFromRight {
    from {
      opacity: 0;
      transform: translate(100%);
    }
    to {
      opacity: 1;
      transform: translate(0);
    }
  }
  
  @media screen and (max-width:750px){
    .scrollToTop {
        bottom: 20px;
        right: 5px;
      }
  }