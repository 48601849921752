
.grouplist_table{
	width: 100%;
	border-collapse: collapse;
}

.grouplist_table td,.grouplist_table th{
  padding:15px 20px;
  text-align: center;
  font-size:15px;
  border-bottom: 1px solid rgb(231, 226, 226);
}

.grouplist_table th{
    font-size: 16px;
    font-weight: 500;
    color: #1e5353da;
}

.grouplist_icons_table{
    margin-right:0px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin:  0px 20px;
  }

  .grouplist__icons_wrapper{
    width: 100px;
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  
  .grouplist_editIcon{
    color: var(--blueBtn);
    font-size:24px;
    cursor: pointer;
  }
  
  .grouplist_editIcon:hover{
    transform: scale(1.1);
    transition: all 0.4s ease;
  }
  
  .grouplist_deleteIcon{
   color: var(--redBtn);
   font-size:24px;
   cursor: pointer;
  }
  
  .grouplist_deleteIcon:hover{
      transform: scale(1.1);
      transition: all 0.4s ease;
  }

  .grouplist__downloadIcon{
    color: #91830cea;
    font-size:24px;
    width: 100%;
    display: grid;
    grid-column: 2/3;
    margin: 8px 0px -26px 0px;
  }

.icons_table_btn{
    text-decoration: none;
    display:flex;
    justify-content: center;
    align-items:center;
    height:95px;
    width:90px;
}

.live_img{
  object-fit: cover;
  height: 100%;
  width: 100%;
  margin-right: 10px;
}

.offline_img{
  object-fit: cover;
  height: 100%;
  margin-left: 20px;
  width: 100%;
}

.icons_table_btn:hover{
    transition: all 0.4s ease-in-out;
    transform: scale(1.1);
}

.copyUrl_div{
  display: flex;
  flex-direction:row;
  justify-content: center;
  width: 27vw;
  padding: 5px 0px;
}

.copy_groupUrl_btn{
  border: 0;
  outline: 0;
  background: var(--blueBtn);
  width: 170px;
  height:37px;
  margin: 4px 8px;
  font-size: clamp(11px , 2vw , 12px);
  border-radius: 50px;
  color: white;
}

.copy_groupUrl_btn:hover{
  cursor: pointer;
  background: #303058e0;
  transition: all 0.4s ease-in-out;
}

.copied_message{
  color:var(--errorColor);
  margin-bottom: 10px;
  margin-top: -10px;
  animation: animate 1.5s linear infinite;
}

@keyframes animate{ 
  0%{ 
    opacity: 0; 
  } 
  50%{ 
    opacity: 1; 
  } 
  100%{ 
    opacity: 0; 
  } 
}

/* this is for copyUrl PopUp Title */

.copyUrl_title{
  font-size: 18px;
}


@media(max-width: 800px){

  .grouplist_table thead{
		display: none;
    }

     .grouplist_table td,.grouplist_table th{
        padding:12px 20px;
        border:1px solid #ddd;
        font-size:14px;
      }

	.grouplist_table, .grouplist_table tbody, .grouplist_table tr, .grouplist_table td{
		display: block;
		width: 100%;
    }
    
	.grouplist_table tr{
		margin-bottom:15px;
  }
    
	.grouplist_table td{
		text-align: right;
		padding-left: 35%;
		text-align: right;
		position: relative;
  }
  
	.grouplist_table td::before{
		content: attr(data-label);
		position: absolute;
		left:0;
		width: 50%;
		padding-left:12px;
		font-size:14px;
		font-weight: 500;
    text-align: left;
    color: rgba(30, 83, 83, 0.856);
  }
  
    .grouplist_icons_table{
      margin-right:5px;
      display: flex;
      align-items: center;
      width: 100%;
      margin:  0px 5px;
    }
  

     .grouplist_editIcon{
        margin-left: 10px;
      }

      .icons_table_btn{
        text-decoration: none;
        display:flex;
        justify-content: center;
        align-items:center;
    }
    
    .live_img{
      height: 80px;
      margin-right: -35px;
    }
    
    .offline_img{
      height: 80px;
      margin-right: -25px;
    }
      .copyUrl_div{
         display: flex;
         flex-direction:column;
         align-items: flex-end;
          width: 100%;
      }
      .copy_groupUrl_btn{
        width: 160px;
        height:35px;
        margin: 4px 8px;
      }

      .copyUrl_title{
        font-size: 13px;
      }

      .grouplist__downloadIcon{
        margin: 8px 0px -10px 0px;
      }
}
@media(max-width: 420px){
  .grouplist_table td,.grouplist_table th{
    padding:12px 20px;
    border:1px solid #ddd;
    font-size:13px;
  }
  .grouplist_table td::before{
		padding-left:10px;
		font-size:14px;
	
  }
  .copyUrl_div{
    margin-top: 10px;
     width: 100%;
   }
 .copy_groupUrl_btn{
   width: 160px;
   height:35px;
   font-size: 10px;
 }

  .copyUrl_title{
    font-size: 11px;
  }
  
}


