.accountDetails{
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 80vh;
    font-family: 'Roboto';
    margin-top: 20px;
}

.updated_accountDetails{
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 85vh;
    font-family: 'Roboto';
    margin-top:20px;
}

.accountDetails_container{
    width: 90vw;
    height:99%;
    background-color:var(--containerBgColor);
    display: flex;
    flex-direction: column;
    padding: 10px;
    align-items: center;
    border-radius: 10px;
    box-shadow: var(--boxShadow);  
}

.accountDetails_title{
    font-size: 25px;
    color:var(--color);
    margin:5px 0px;
    text-align: center;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content:center;
}

.accountDetails_wrapper{
    width: 100%;
    height: 78%;
    display: flex;
    justify-content: center;
    margin-bottom: 0px;
}

.accountDetails_btn{
    outline: none;
    border: none;
    padding:12px 24px;
    border-radius: 20px;
    margin:0px 20px;
    margin-top: -5px;
    color: white;
   background:var(--redBtn);
   cursor: pointer;
  }
  .accountDetails_btn:hover{
      padding:12px 24px;
      transition:all 0.3s ease-in-out;
      background-color:var(--redBtnHover);
 }
 .btn_wrapper {
    float:left;
  width:100%;
  text-align:center;
  } 
/* left_section */

.accountDetails_left{
    flex: 0.4;
    text-align: left;
    display: flex;
    flex-direction: column;
    align-items:flex-end;
}
.text_wrapper_left{
    margin-right: 30px;
}

.text_wrapper_left > h5{
  font-size:16px;
  padding:6px 0px;
  color: #1e5353da;
}
.text_padding{
 height: 65px;
 display: flex;
 align-items: center;
}

/* right-section */
.accountDetails_right{
     flex: 0.4;
     display: flex;
     flex-direction: column;
     align-items:flex-start;   
}

.text_wrapper_right{
    margin-left: 70px;  
}

.text_wrapper_right > h5{
    font-size:15px;
    font-family: 400;
    padding:6px 0px;
    color:#141414ec;
}
.textarea_wrapper{
    display: flex;
    flex-direction: column;
}

.text_area{
    width: 100%;
    height: 60px;
    margin:3.5px 0px;
    border: 1px solid lightgrey;
    outline: none;
    resize: none;
    border-radius: 4px;
    font-size:14px;
    font-weight: 600;
    font-family: 'Roboto', sans-serif;
}

.text_area::-webkit-input-placeholder{
    color:#141414ec !important;
}

/* radio-section */

.radio_container{
    width: 100%;
    height: 64px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.radio_wrapper{
    display:flex;
    align-items:center;
    margin: 2px 4px;
    font-size: 14px;
    font-weight: 600;
    color:#141414ec;
}

#approved{
    margin: 2.5px 5px;
    margin-left: 0px;
}
#rejected{
    margin: 2.5px 5px;
    margin-left: 0px;
}


.accountDetails_checkbox_wrapper{
    width:100%;
    font-size: 13.9px;
    display: flex;
    flex-direction: column;
}
.accountDetails_checkbox{
    display:flex;
    align-items: center;
}
.accountDetails_checkbox > input {
    margin:10px 10px 10px 5px;
}
.accountDetails_checkboxName{
   font-size:15px;
   font-weight: 600;
   color:#141414ec;
}
.checkbox_disclaimer{
    font-size:14px;
    font-weight: 600;
    color: var(--errorColor);
}

/* responsive */

@media screen and (max-width:750px){
    .accountDetails_title{
        font-size: 20px;
        margin:5px 0px;
        text-align: center;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content:center;
    }
.text_wrapper_left{
    margin-right: 10px;
}
.text_wrapper_left > h5 {
    font-size: 14px;
}

/* right-section */

.text_wrapper_right{
    margin-left: 15px;
}
.text_wrapper_right > h5{
    font-size: 13px;
}

/* radio-section */

.radio_container{
    margin-top: 4px;
}


.accountDetails_checkbox_wrapper{
    width:100%;
    display: flex;
    flex-direction: column;
}
.accountDetails_checkbox{
    display:flex;
    align-items: center;
}
.accountDetails_checkbox > input {
    margin:10px 10px 10px 5px;
}
.accountDetails_checkboxName{
   font-size:13px;
}
.checkbox_disclaimer{
    font-size:12px;
}

}

@media screen and (max-width:350px){
    .accountDetails_wrapper{
        width: 100%;
        height: 65%;
        display: flex;
        justify-content: center;
    }
    
.accountDetails_left{
    flex: 0.3;
    text-align: left;
    display: flex;
    flex-direction: column;
    align-items:flex-end;
}
.text_wrapper_left{
    margin-right: 10px;
}
.text_wrapper_left > h5{
  font-size:12px;
  padding:5px 0px;
}
.text_padding{
 height: 45px;
 display: flex;
 align-items: center;
}

/* right-section */
.accountDetails_right{
     flex: 0.3;
     display: flex;
     flex-direction: column;
     align-items:flex-start;
}

.text_wrapper_right{
    margin-left: 10px;
}

.text_wrapper_right > h5{
    font-size:11px;
    font-family: 400;
    padding:5px 0px;
}
.accountDetails_checkboxName{
    font-size:11px;
 }
 .checkbox_disclaimer{
     font-size:10px;
 }

.text_area{
    width: 100%;
    height: 40px;
    margin:2.5px 0px;
    border: var(--lightGreyBorder);
    outline: none;
    resize: none;
    border-radius: 4px;
}

/* radio-section */

.radio_container{
    width: 100%;
    height: 25px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 10px;
}

.radio_wrapper{
    display:flex;
    align-items:center;
    margin: 0px 0px;
    font-size: 11px;
}

#approved{
    margin: 2px 3px;
}
#rejected{
    margin: 2px 3px;
}

    
}