
.tab_container{
 display: flex;
 flex-direction: column;
 align-items:center;
}

.tab_wrapper{
    width: 75%;
}

.tabs_mainTitle {
    font-size:clamp(20px,2vw,25px);
    color:var(--darkTitle);
}

.app_bar{
    margin: 20px 0px;
    border-radius: 12px;
}

.tabs{
    display: flex;
    align-items:center;
    height: 60px;
    background-color: #123075a9;
    border-radius: 12px;
    padding:35px;
}

.tab_btn_div{
    margin:10px 0px;
    display: flex;
    align-items: center;
    justify-content:center;
    width:70%;
}

.group_back_btn{
    padding: 12px 24px;
    background-color: #4c4ccf;
    color: white;
    text-decoration:none;
    border-radius: 100px;
    font-size: 14px;
    margin-left: -120px;
    margin-right:40px;
}

.group_back_btn:hover{
     background-color: var(--redBtnHover);
     transition: all 0.4s ease-in-out;
}

.tab_btn{
    outline: none;
    border: none;
    padding:14px 55px;
    border-radius: 100px;
    margin:10px 0px;
    color: white;
    background: var(--redBtn);
    cursor: pointer;
    font-size: 16px;
}
  .tab_btn:hover{
    transition:all 0.4s ease-in-out;
    background-color:var(--redBtnHover);
 }

@media screen and (max-width:700px){
    .tab_wrapper{
        width: 90%;
    }

    .tabs{
        display: flex;
        align-items:center;
        height: 65px;
        background-color: #123075a9;
        border-radius: 8px;
        padding:10px;
    }

    .tab_title{
        font-size:11px !important;
    }

    .group_back_btn{
        padding: 10px 24px;
        margin-left: -80px;
        margin-right:40px;
    }
}
