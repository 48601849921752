body{
    background-color: #e5e5e5;
}

input[type="radio"]{
  cursor: pointer;
  height: 16px;
  width: 16px;
}

input[type="checkbox"]{
  cursor: pointer;
}

input:focus{
  outline: 0;
  border: 1px solid var(--blueBtn);
}
textarea:focus{
  outline: 0;
  border: 1px solid var(--blueBtn);
}

:root{
    --wireFrameBorder: 1px solid black;
    --color:#455A64;
    --color-white:#fff;
    --darkTitle:#29292ba4;
    --errorColor:#dd2e2efa;
    --redBtn:#c31818;
    --blueBtn:#4e5dd3;
    --redBtnHover:#2e2c2c;
    --transition: all 0.4s ease-in-out;
    --boxShadow: #00000059 0px 5px 15px;
    --dark-boxShadow:0px 10px 20px #00000023;
    --containerBgColor:#FFFFFF;
    --lightGreyBorder: 1px solid lightgrey;
    --popupTitle:#202020;
}

/* CSS FOR NEW INPUT FIELDS */
    .form__input_wrapper{
    display: flex;
    flex-direction: column;
    width: 100%;
    }

    .formManageGroup__input_wrapper{
      align-items: center;
    }

    
    .form__input_label{
    font-size: clamp(11px , 2vw , 12px);
    margin: 0px 0px 6px 15px;
    font-weight: 700;
    color: var(--color);
    }

    .formManageGroup__input_label{
      text-align: left;
      width:40vw;
      /* border: var(--wireFrameBorder); */
    }

    /* CSS FOR INPUT ERROR'S */

    .form_dropdown_error{
        color:var(--errorColor);
        font-size: clamp(11px , 2vw , 12px);
        font-weight: 600;
        margin-bottom: 10px;
        margin-top: -15px; 
        animation: animate 1.5s linear infinite;
        text-align: center;
    }
    
    .ChatExportPopUp__error{
      margin-top: 5px;
      margin-bottom: 0px;
    }

    .selectVideo__fetchError{
      margin-bottom: 0px;
      margin-top: 0px; 
      margin: 5px 0px;
    }
    
    .formLogin__input_error{
        margin-bottom: 0px;
        margin-top: 0px; 
    }

    .loading__disclaimer{
      margin-bottom: 20px;
      margin-top: -10px; 
    }

    .form_error{
        color:var(--errorColor);
        font-size: 14px;
        font-weight: 600;
        margin: 0px;
        margin-top: -10px;
        animation: animate 1.5s linear infinite;
    }
    
    
    .submit_error{
        color:var(--errorColor);
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 20px;
        margin-top: -7px;
        animation: animate 1.5s linear infinite;
    }
    
    .expRequired{
        color:var(--errorColor);
        font-size: clamp(11px , 2vw , 12px);
        font-weight: 600;
        margin-bottom: 18px;
        margin-top: -10px;
        width: 100%;
        text-align: center;
    }
       
      @keyframes animate{ 
        0%{ 
          opacity: 0; 
        } 
        50%{ 
          opacity: 1; 
        } 
        100%{ 
          opacity: 0; 
        } 
      }
       
     /* For Loading Bar*/
      .loading__bar-wrapper{
        width: 60%;
        color: var(--color-white);
        border-radius: 50px;
       border: var(--lightGreyBorder);
       height:25px;
       overflow:hidden;
       padding:2px;
       margin: -8px 0 15px 0;
       box-shadow: var(--dark-boxShadow);
    }
   
    .loading__bar{
       background: linear-gradient(to right bottom,var(--blueBtn), #cc7171);
       height:100%;
       border-radius: 50px;
       transition:var(--transition);
       display: grid;
       place-content: center;
       color: var(--color-white);
       font-size: clamp(10px , 2vw , 12px);
       padding: 0 10px;
    }
    

    @media  screen and (max-width: 750px) {
      .formManageGroup__input_label{
        width:70vw;
      }
      
    }
    