   .ChatExportPopUp{
    color: var(--color);
    }
    
    .ChatExportPopUp__title_wrapper{
        display: flex;
        align-items: center;
        justify-content:space-between;
        padding: 0px 0px 10px 0px;
        border-bottom: 1px solid #e4e3e3d3;
        margin-bottom:8px;
    }
    
    .ChatExportPopUp__title{
        width: 100%;
        text-align: center;
        font-size: clamp(12px,2vw,16px);
    }
    
    .ChatExportPopUp__icon{
        font-size: clamp(22px,2vw,24px);
        background-color: #e6e3e3;
        padding: 5px;
        border-radius: 50px;
        margin-right: 6px;
        transition:var(--transition);
    }
    
    .ChatExportPopUp__icon:hover{
    cursor: pointer;
    transform:scale(1.1);
    transition:var(--transition);
    }

    .ChatExportPopUp__inputFields-wrapper{
        display: flex;
        width: 100%;
        /* border: var(--wireFrameBorder); */
        padding: 10px 0px;
    }

    .ChatExportPopUp__input-wrapper{
        display: flex;
        flex-direction: column;
        width: 50%;
        padding: 15px 20px;
        /* border: var(--wireFrameBorder); */
    }

    .ChatExportPopUp__input{
        width: 100%;
    }

    .ChatExportPopUp_btnWrapper{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content:center;
        align-items: center;
        padding:10px;
    }
    .ChatExportPopUp_btn{
        width: 30%;
    }


      @media screen and (max-width:800px){
        .ChatExportPopUp__inputFields-wrapper{
            display: flex;
            flex-direction: column;
            padding: 10px 0px;
        }
        .ChatExportPopUp__input-wrapper{
            width: 100%;
            padding: 5px 5px
        }
        .ChatExportPopUp_btn{
            width: 80%;
            margin-right: 20px !important;
        }
      }