
.PageNotFound{
    height: 99vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.PageNotFound_wrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #faf0f0dc;
    border-radius: 20px;
    width: 80vw;
    height: 80vh;

}

.PageNotFound_svg{
  height: 70%;
  object-fit: cover;
  
}
.PageNotFound_title{
 display: flex;
 align-items: center;
 padding: 10px;
}

.PageNotFound_title > h2{
    color: var(--errorColor);
    margin:0px 10px;
}

.sad_svg{
font-size:60px;
color: rgb(19, 18, 18);
}

.PageNotFound_btn{
    margin:20px 0px;
    outline:0;
    border:0;
    padding:15px 25px;
    font-size:16px;
    border-radius: 50px;
    background:var(--blueBtn);
    color: white;
    cursor: pointer;
}

.PageNotFound_btn:hover{
    background: #a9aaad;
    color: black;
    transition: all 0.4s ease-in-out;
}

@media screen and (max-width: 750px){

.PageNotFound_wrapper{
    width: 90vw;
    height: 80vh;
}

.PageNotFound_svg{
  height: 60%; 
}

}

@media screen and (max-width: 420px){

    .PageNotFound{
        height: 100vh;
    }
    
    .PageNotFound_wrapper{
        width: 90vw;
        height: 80vh;
    }
    
    .PageNotFound_svg{
      height: 45%;
    }
    .PageNotFound_title{
        margin-bottom: 20px;
    }
    .PageNotFound_title > h2{
        font-size:20px;
    }

}

