.admin_home{
    height:88vh;
    width: 100%;
    background: #D3D3D3;
}

.admin_home_container{
    height:60%;
    display:flex;
    align-items:center;
    justify-content: space-around;
}

.admin_home_left{
    width:450px;
    height:350px;
    padding: 10px;
    display:flex;
    flex-direction: column;
    justify-content:center;
    margin-right: 15px;
    margin-top: 10px;   
}

.admin_button_top{
    height:50%;
    display:flex;
    align-items:center; 
}

.admin_button_middle{
    height:50%;
    display:flex;
    align-items:center;
    margin-top: 5px ;
    margin-bottom: 15px;
}

.admin_button_bottom{
    height:50%;
    display:flex; 
}

.admin_link{
   border: 0;
   outline:0;
   width: 140px;
   height:85px;
   background-color:#F03C14;
   color:#fff;
   border-radius: 24px;
   text-decoration:none;
   cursor: pointer;
   font-size: 16px;
   transition:var(--transition);
}

.admin_link:hover{
    background-color:var(--redBtnHover);
    transition:var(--transition);
}

.manage_btn_link{
   width: 140px;
   height:85px;
   margin:0px 30px ;
   text-decoration:none;
}

.admin_home_right{
    width:520px;
    height:350px;
    margin-top: 45px;
    margin-right: 15px;
}

.chat_svg{
    height:100%;
    width: 100%;;
    object-fit: contain; 
}

.wave_svg{
    object-fit: cover;
    overflow-y: hidden;
    position:absolute;
    bottom: 0;
    height: 230px;
    width: 100%;
}


   /* Responsive Mobile-View */

@media screen and (max-width:800px){
  
.admin_home_container{
    height:78%;
    display:flex;
    flex-direction:column;
    align-items:center;
}

.admin_home_left{
    width: 80vw;
    height:230px;
    display:flex;
    flex-direction: column;
    align-items:center;
    margin-top: 60px;
}

.admin_button_top{
    height:50%;
    display:flex;
    align-items:center;
}
.admin_button_middle{
    height:50%;
    display:flex;
    align-items:center;
    margin: 0px 0px;
}
.admin_button_bottom{
    height:50%;
    display:flex;
    align-items:center;
}
.admin_link{
    width: 110px;
    height:65px;
    font-size: 14px;
    margin-top: 10px;
 }
 .admin_link:hover{
     transition:all 0.4s ease-in-out;
 }
 .manage_btn_link{
    width: 110px;
    height:65px;
    margin:10px 30px;
 }

.admin_home_right{
    width:90vw;
    height:210px;
    margin-top: 40px; 
    margin-right: 0px;
}

.chat_svg{
    height:100%;
    width: 100%;;
    object-fit: contain; 
}
.wave_svg{
    object-fit: cover;
    overflow-y: hidden;
    height: 100px;
    width: 100%;
    position: absolute;
    bottom: 0;
}

}

@media screen and (max-width:450px){
    .admin_home{
        height:91.5%;
        width: 100%;
        background: #D3D3D3;
        position: relative;
    }
    .admin_home_container{
        height:84%;
        display:flex;
        flex-direction:column;
        align-items:center;
    }
    .wave_svg{
        object-fit: fill;
        overflow-y: hidden;
        position:absolute;
        bottom: 0px;
        height: 105px;
        width: 100%;
    }
    .admin_home_right{
        width:90vw;
        height:270px;
        margin-top: 35px; 
        margin-right: 0px; 
    }
    
    .chat_svg{
        height:100%;
        width: 100%;;
        object-fit: contain; 
    }
}

 

@media screen and (max-width:380px){
    .admin_home{
        height:91%;
        width: 100%;
        background: #D3D3D3;

    }
    .admin_link{
        width: 90px;
        height:60px;
        border-radius: 20px;
        font-size:13px;
     }
     .admin_link:hover{
         transition:all 0.4s ease-in-out;
     }
     .manage_btn_link{
        width: 90px;
        height:60px;
        margin:10px 30px;
     }
     .admin_home_container{
        height:80%;
        display:flex;
        flex-direction:column;
        align-items:center;
    }
    .wave_svg{
        object-fit: fill;
        overflow-y: hidden;
        position:absolute;
        bottom: 0;
        height: 100px;
        width: 100%;
    }
    .admin_home_right{
        width:90vw;
        height:240px;
        margin-top: 10px; 
        margin-right: 0px;
    }

    .chat_svg{
        height:100%;
        width: 100%;;
        object-fit: contain; 
    }
    
}
@media screen and (max-width:350px){
    .admin_link{
        width: 80px;
        height:60px;
        border-radius: 18px;
        font-size: 12px;
     }
     .manage_btn_link{
        width: 80px;
        height:60px;
        margin:5px 20px;
     }
}

@media screen and (max-height:550px){
   .admin_home{
    height:90vh;
    width: 100%;
    background: #D3D3D3;
}
.admin_home_container{
    height:65%;
    display:flex;
    flex-direction:row;
}

.admin_home_left{
    width:250px;
    height:140px;
    display:flex;
    flex-direction: column;
    margin-right: 15px;
}

.admin_button_top{
    height:50%;
    display:flex;
    align-items:center;
}

.admin_button_bottom{
    height:50%;
    display:flex;
    align-items:center;
}
    .admin_link{
        border: 0;
        outline:0;
        width: 80px;
        height:54px;
        color:#fff;
        border-radius: 20px;
        text-decoration:none;
        cursor: pointer;
        font-size: 12px;
     }
     .admin_link:hover{
         transition:all 0.4s ease-in-out;
     }
     .manage_btn_link{
        width: 80px;
        height:54px;
        margin:0px 20px;
     }

     .admin_home_right{
        width:300px;
        height:140px;
    }
    
    .chat_svg{
        height:100%;
        width: 100%;
        object-fit: contain; 
    }

    .wave_svg{
        object-fit: cover;
        overflow-y: hidden;
        height: 110px;
        width: 100%;
        position: absolute;
        bottom: -20px;
    }

}