.CreateGame{
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: 'Roboto';
    margin-top: 20px;
}

.CreateGame_wrapper{
    width: 90vw;
    background-color:var(--containerBgColor);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    border-radius: 10px;
    box-shadow: var(--boxShadow); 
}

.CreateGame_title{
    font-size: 25px;
    color: var(--color);
    margin: 20px 25px;
    text-align: center;
}

.CreateGame_form{ 
    display: flex;
    flex-direction: column;
    align-items: center;
}

.CreateGame_input{
    width:40vw;
    height: 40px;
    padding-left: 20px;
    border: 1px solid #DFE1E8;
    border-radius: 20px;
    margin-bottom: 25px;
    outline: none;
}

.CreateGame_textarea{
    width: 100%;
    height: 150px;
    margin-bottom:30px;
    border: var(--lightGreyBorder);
    outline: none;
    resize: none;
    border-radius: 10px;
    padding: 10px 10px;
}

.CreateGame_btn_div{
    margin:20px 0px;
}

.CreateGame_btn{
    outline: none;
    border: none;
    padding:15px 30px;
    border-radius: 100px;
    margin:10px 0px;
    color: white;
    background:var(--redBtn);
    cursor: pointer;
    font-size: 16px;
}

.CreateGame_btn:hover{
    transition:all 0.3s ease-in-out;
    background-color:var(--redBtnHover);
 }

/*responsive*/

@media(max-width: 750px){

.CreateGame_title{
    font-size: 18px;
}
.CreateGame_form{ 
    width: 85%;
}

.CreateGame_input{
    width:100%;
    height: 40px;
    padding-left: 20px;
    margin-bottom: 30px;
}
   
}
