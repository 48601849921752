.select_questions{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 20px;
    flex-wrap: wrap;
    width: 50vw;
    overflow-y: scroll;
    max-height: 280px;
    min-height:50px;
}

.select_questions_left{
    display: flex;
    flex-direction: column;
    flex:1;
}

.select_questions_left_search{
    background-color: lightgray;
    width: 100%;
    padding: 10px 40px 10px 20px;
    display: flex;
    justify-content: center;
    font-size:15px;
}

.select_questions_left_search > h4{
  color: #111111;
  font-size:14px;
}

.question_list_item{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 4px 20px;
    background-color: #eee;
}

.question_text{
  margin: 8px 7px;
  font-size: 14px;
}

.plus_icon{
    cursor: pointer;
    width: 25px;
    height: 25px;
    color: rgb(26, 10, 10);
}

.plus_icon:hover{
    color: var(--blueBtn);
    transition:all 0.3s ease-in-out;
    transform: scale(0.95);
}

.select_questions_right{
    display: flex;
    flex-direction: column;
}

.fetch_btn{
    outline:0;
    border:0;
    padding:12px 20px;
    margin: 10px 0px;
    font-size:13px;
    border-radius: 50px;
    background:  var(--blueBtn);
    color: white;
    cursor: pointer;
}

.fetch_btn:hover{
    background: rgb(80, 80, 138);
    transition: all 0.4s ease-in-out;
}

.loading_disclaimer{
   text-align: center;
   margin: 30px 0px 10px 0px;
   font-size: 17px;
   font-weight: 600;
   color:  var(--errorColor);
   animation: animate 1.5s infinite linear;
}

@keyframes animate{ 
    0%{ 
      opacity: 0; 
    } 
    50%{ 
      opacity: 1; 
    } 
    100%{ 
      opacity: 0; 
    } 
  } 

  .legend_wrapper{
  width: 75%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 5px 0px;
  }

 .legend_container{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 120px;
    margin: 5px 0px;
 }

 .legend_red{
    background:var(--redBtn); 
    color: var(--redBtn); 
    height: 10px;
    width: 10px;
    border-radius: 100px;
   }

  .legend_blue{
  background: #123075;
  color: #123075;
  height: 10px;
  width: 10px;
  border-radius: 100px;
  }

  .legend_text{
   margin-left: 10px;
   font-size: 14px;
   color: #123075;
  }

@media only screen and (max-width: 685px) {
    .select_questions{
        display: flex;
        flex-direction: column !;
        margin-right: 0px;
        overflow: scroll;
        max-height: 60vh;
        min-height:auto;
        height: auto !important;
        width: 60vw;
        margin-bottom: 15px;
    }
    .select_questions_left_search{
        width: 60vw;
    }

    .legend_wrapper{
        width: 85%;
     }

  .legend_text{
   margin-left: 6px;
  }
      
}