.viewgroups_btn{
    outline: none;
    border: none;
    padding:13px 35px;
    border-radius: 100px;
    color: white;
    background: var(--redBtn);
    cursor: pointer;
    font-size: 15px;
}
.viewgroups_btn:hover{
    transition:all 0.4s ease-in-out;
    background-color:var(--redBtnHover);
}

@media screen and (max-width: 768px){
    .viewgroups_btn{
        padding:13px 30px;
        font-size: 13px;
    }
}