.Login{
    overflow-x: hidden;
    width: 100vw;
    height: 100vh;
    background-color: #123075;
    position: relative;
    overflow: hidden;
}

.Homepage__bgbox1{
    position: absolute;
    left:60vw;
    top:-15vh;
    width: 1000px;
    height: 1000px;
    background-color: rgba(26,71,176, 0.2);
    border-radius: 100px;
    transform: rotate(-45deg);
    z-index: 1;
}
  
.Homepage__bgbox2{
    position: absolute;
    left:11vw;
    top:40vh;
    width: 1000px;
    height: 1000px;
    background-color: rgba(26,71,176, 0.2);
    border-radius: 100px;
    transform: rotate(-45deg);
    z-index: 1;
}

.Login__logo{
    font-size: 24px;
    color: white;
    font-weight: 600;
    margin-left: 4vw;   
}

.Login__main{
    display: flex;
    flex-direction: row;
    height: 80vh;
    flex-wrap: wrap;
    z-index: 3;
    margin-bottom: 200px;
    margin-top: 100px;
}

.Login__left{
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 3;
}


.Login__bigText{
    font-family: 'Roboto';
    font-size: 48px;
    color: white;
    z-index: 3;
}

.login__smallText{
    font-family: 'Roboto';
    font-size: 18px;
    color: #999999;
    margin-top: 20px;
}

.login__left_link{
    text-decoration: underline;
    color: #fff;
}

.forgot_title{
    font-family: 'Roboto';
    font-size: 45px;
    color: white;
    z-index: 3;
}
.forgot_description{
    font-family: 'Roboto';
    font-size: 16px;
    color: #999999;
    margin-top: 20px;
    margin-bottom: 40px;
    text-align: center;
    width: 100%;
}
.Login__right{
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 3;
}
.Login__right_form{
    background-color: white;
    padding: 30px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 3;
}

.Login__field{
    width: 300px;
    height: 50px;
    border: 1px solid #9AA5C0;
    padding: 5px 20px ;
    margin-bottom: 10px;
    border-radius: 5px;
}

.Login__forgot_pass{
    color: #474C5C;
    text-decoration: none;
    margin-top: 10px;
}

.Login__button{
    background: linear-gradient(100deg, rgba(250,43,86, 1) 0%, rgba(249,28,61, 1) 25%);
    width: 300px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FFFFFF;
    border-radius: 5px;
    margin-top: 30px;
    outline: 0;
    cursor:pointer;
    border: 0;
    font-size: 16px;
}



.login_error{
    color:red;
    font-size: 12px;
    font-weight: 700;
    margin-top: -15px;
    margin-bottom: 20px;
}

.login_backend_error{
    color:red;
    font-size: 14px;
    font-weight: 600;
    margin-top: 10px;
    margin-bottom: -10px;
    animation: animate 1.5s linear infinite;
}

@keyframes animate{ 
    0%{ 
      opacity: 0; 
    } 
    50%{ 
      opacity: 1; 
    } 
    100%{ 
      opacity: 0; 
    } 
  } 

@media  screen and (max-width: 750px) {
    .hide_on_mobile{
        display: none;
    }
    .Login__main{
        display:flex;
        flex-direction: column;
        margin-bottom: 100px;
        margin-top: 40px;
    }
    .forgot_title{
        font-family: 'Roboto';
        font-size: 40px;
        color: white;
        z-index: 3;
    }
    .forgot_description{
        font-family: 'Roboto';
        font-size: 15px;
        color: #999999;
        margin-top: 20px;
        margin-bottom: 0px;
        width: 90%;
    }
}