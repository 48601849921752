.createVideo_radioConatiner{
    width:90%;
    display: flex;
    align-items: center;
    justify-content:center;
    padding:5px;
}

.createVideo_radioConatiner > input{
  margin: 0px 10px;
}

.createQuestion_textarea{
    width: 98%;
    height: 120px;
    border: var(--lightGreyBorder);
    outline: none;
    resize: none;
    border-radius: 10px;
    padding: 10px;
    margin: 0px 0px 20px 0px;
}

.createQuestion_ChoiceForm{
    display: flex;
    margin-top:20px;
}

.createQuestion_ChoiceForm1{ 
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 10px 10px;
}
.createQuestion_ChoiceForm2{
    display: flex;
    flex-direction: column;
    margin: 10px 10px;
}

.maximum_disclaimer{
font-size: 14px;
color: var(--errorColor);
margin: -10px 0px 10px 0px;
}

.choose_file{
font-size:14px;
background: white;
border-radius: 50px;
box-shadow: 5px 5px 10px #1313131e;
border: var(--lightGreyBorder);
width: 80%;
padding:3px;
outline: none !important;
margin:2px 0px 26px 0px;
cursor: pointer;
}

::-webkit-file-upload-button{
    color: white;
    background: var(--redBtn);
    padding: 10px 20px;
    border: none;
    border-radius: 50px;
    box-shadow: 1px 0 1px #6b4559;
    outline: none;
    cursor: pointer;
    margin-right:10px ;
}

::-webkit-file-upload-button:hover{
    transition:all 0.4s ease-in-out;
    background-color:var(--redBtnHover);
}


/*responsive*/

@media(max-width: 750px){
     .createVideo_radioConatiner{
        margin-bottom: 10px;
    }
    .createQuestion_ChoiceForm{ 
        width: 90%;
        display: flex;
        flex-direction: column;
    }
    .createQuestion_ChoiceForm1{ 
        width: 100%;
        margin:0px;
    }
    .choose_file{
        width: 100%;
     }
    .createQuestion_ChoiceForm2{
        width: 100%;
        margin:0px;
    }
    
    .createQuestion_textarea{
        width: 100%;
        height: 120px;
        margin:10 0px;
    }
      
    }