
.ChatGroupCreate{
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: 'Roboto';
    margin-top: 20px;
  
}

.ChatGroupCreate__form__title{
    font-size: 25px;
    color:var(--color);
    margin: 20px;
    margin-bottom: 25px;
}

.ChatGroupCreate_Disclaimer{
 width: 80%;
 margin-bottom:30px;
 font-size:15px;
 text-align:center;
 color:var(--errorColor);
}


.ChatGroupCreate__form{
    width: 90vw;
    background-color: var(--containerBgColor);
    display: flex;
    flex-direction: column;
    padding: 10px;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    box-shadow: var(--boxShadow);
}

.ChatGroupCreate__newform-wrapper{
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-items: center;
}

.ChatGroupCreate__input_wrapper{
      display: flex;
      flex-direction: column;
      width: 85%;
      margin: 2px 0px;
} 

.ChatGroupCreate_textarea{
    width: 100%;
    height: 100px;
    border: var(--lightGreyBorder);
    outline: none;
    resize: none;
    border-radius: 2px;
    padding: 10px;
    margin: 0px 0px 15px 0px;
}

.ChatGroupCreate__form__input{
    width:100%;
    height: 40px;
    padding-left: 10px;
    border: 1px solid #DFE1E8;
    margin-bottom: 20px;
    outline-color: var(--blueBtn);
}

#description{
    width:40vw;
    padding-left: 10px;
    border: 1px solid #DFE1E8;
    margin-bottom: 20px;
    font-family: 'Roboto';
    padding-top: 10px;
}

select{
    width:41vw;
    height: 50px;
    padding-left: 10px;
    border: 1px solid #DFE1E8;
    margin-bottom: 20px;
    font-family: 'Roboto';
}

.ChatGroupCreate__options-wrappper{
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.option_row{
    display: flex;
    flex-direction: column;
    width:100%;
    justify-content: center;
    /* align-items: center; */
    border: 1px solid #DFE1E8;
    padding: 5px 15px;
    border-radius: 5px;
}

.checkbox{
    width: 100%;
    margin: 10px 0px;
    display: flex;
    align-items: center; 
}

.checkbox > input{
    margin:0px 15px;
}

.checkbox_name{
   font-size:clamp(13px , 2vw , 15px);
}


.ChatGroupCreate__smallText{
    font-size: 14px;
    margin: 10px 0px;
    color: var(--color);
   font-weight: 500;
}


.terms_row{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom:10px;
}

/* CSS FOR WAITING ROOM */

.ChatGroupCreate__waitingRoom-wrapper{
width:100%;
min-height: 300px;
max-height: auto;
margin: 20px 0px;
color:var(--color);
/* border:1px solid black; */
}

.ChatGroupCreate__waitingRoom-title{
width: 100%;
text-align: center;
font-size:clamp(15px , 2vw , 18px);
font-weight: 500;
padding: 15px 0px;
margin: 10px 0px 0px 0px;
border-bottom: 1px solid #f3f1f1;
border-top: 1px solid #f3f1f1;
}

.ChatGroupCreate__waitingRoom-container{
    display: grid;
    grid-template-columns:55% 45%;
    margin: 20px 0px;
    padding:0px 20px;
}

.ChatGroupCreate__textEditor-wrapper{
    padding:10px 15px;
    border: 1px solid #f3f1f1;
    border-radius: 10px;
    overflow-x: scroll;
}

.ChatGroupCreate__waitingRoom-label{
    font-size:clamp(12px , 2vw , 13px);
    margin: 15px 0px;
    font-weight: 600;
    margin-left: 20px;
}

.ChatGroupCreate__uploadImage-wrapper{
display: flex;
flex-direction:column;
align-items: center;
height:100%;
padding: 10px 20px;
}
.uploadFile-wrapper{
width: 90%;
padding: 0px 5px;
color:var(--color);
}

.ChatGroupCreate__input{
    width:100%;
}

.ChatGroupCreate__uploadBtn-wrapper{
    display: flex;
    align-items: center;
}

@media(max-width: 750px){
    .ChatGroupCreate__newform-wrapper{
        grid-template-columns: 1fr;
    }
    .ChatGroupCreate__input_wrapper{
        width: 100%;
    } 
  
    .ChatGroupCreate__form__title{
        font-size: 18px;
    }

    .ChatGroupCreate_Disclaimer{
        font-size:13px;
    }

    .ChatGroupCreate__form__input{
        width:95%;
        margin:0 auto 20px auto;
    }

    .ChatGroupCreate_textarea{
        width:95%;
        margin:0 auto 20px auto;
    }

    .ChatGroupCreate__options-wrappper{
        width: 95%;
    }

    .option_row{
        width: 100%;
    }
    .checkbox{
        width: 100%;
    }

    .ChatGroupCreate__waitingRoom-wrapper{
        width:100%;
        height: auto;
        }
        

    .ChatGroupCreate__waitingRoom-container{
        grid-template-columns:1fr;
        padding:0px 5px;
    }
}


@media screen and (max-width:420px){
    .ChatGroupCreate__form__title{
        font-size: 16px;
    }

    .ChatGroupCreate__form__input{
        width:98%;
    }

    .ChatGroupCreate_textarea{
        width:98%;
    }
}
