/* liveroom css */

.liveroom_table{
	width: 90%;
	border-collapse: collapse;
    margin-left: 15px;
}

.liveroom_table td,.liveroom_table th{
  padding:12px 10px;
  text-align: center;
  font-size:15px;
  border-bottom: 1px solid rgb(231, 226, 226);
}

.liveroom_table th{
    font-size: 15px;
    font-weight: 500;
    color: #1e5353da;
}

.liveroom_wrapper{
    width:100%;
    display: flex;
}

.liveRoom_wrapper_left{
    flex:0.55;
    overflow-x: scroll;
}

.liveRoom_wrapper_templeft{
    flex:0.55;
    overflow-x: scroll;
    height: 95vh;
    overflow-y:scroll;
}

.liveRoom_wrapper_right{
    flex:0.45;
}

.liveroom_body_iframe{
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
}

.liveroom_iframe{
    width:90%;
    height: 600px;
    padding: 4px;
    border:1px solid lightgrey;
    border-radius: 10px;
    background-color: #e5ebeb;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.liveroom_title{
    margin:5px 0px 15px 0px;
    font-size:25px;
    color:var(--darkTitle);
}

.room_operation_btn_div{
    margin-right:10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.details_btn{
    width:100px;
    height:40px;
    display: flex;
    justify-content:center;
    align-items: center;
    border-radius: 100px;
    color: white;
    background: var(--blueBtn);
    cursor: pointer;
    font-size: 14px;
    text-decoration: none;
}
  .details_btn:hover{
    transition:all 0.4s ease-in-out;
    background-color:#717172;
 }

.join_btn{
    width:100px;
    height:40px;
    display: flex;
   justify-content:center;
   align-items: center;
    outline: none;
    border: none;
    border-radius: 100px;
    margin:0px 10px 0px 15px;
    color: white;
    background: var(--redBtn);
    cursor: pointer;
    font-size: 14px;
}

.join_btn:hover{
    transition:all 0.4s ease-in-out;
    background-color:var(--redBtnHover);
 }

 .rooms_btn_div{
    display: flex;
    align-items: center;
    justify-content: center;
    padding:10px;
    width: 100%;
    margin-top: 20px;
 }

 .rooms_back_btn{
    padding:12px 55px;
    border-radius: 100px;
    color: white;
    background:var(--redBtn);
    cursor: pointer;
    font-size: 16px;
    text-decoration: none;
 }

 .rooms_back_btn:hover{
    transition:all 0.4s ease-in-out;
    background-color:var(--redBtnHover);
 }


/* Room_table_details css*/

.LiveroomDetails_wrapper{
    width: 45%;
    display: flex;
    justify-content: center;
    margin-top: 15px;
}
.details_table thead{
    display: none;
}
 .details_table td,.details_table th{
    padding:12px 15px;
    border:1px solid #ddd;
    border-radius:10px;
    font-size:15px;
    margin: 6px 0px;
}
  .details_table td:hover,.details_table th:hover{
   background-color: rgba(243, 235, 235, 0.5);
   transition: all 0.3s ease-in-out;
   cursor: pointer;
   transform: scale(1.01);
}

.details_table, .details_table tbody, .details_table tr, .details_table td{
    display: block;
    width: 100%;
}

.details_table td{
    text-align: right;
    padding-left: 45%;
    text-align: right;
    position: relative;
}
.details_table td::before{
    content: attr(data-label);
    position: absolute;
    left:0;
    width: 50%;
    padding-left:15px;
    font-size:15px;
    font-weight: 600;
    text-align: left;
    color: rgba(30, 83, 83, 0.753);
}

.export_btn{
    border:var(--lightGreyBorder) ;
    padding:8px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background:transparent;
    outline: none;
    font-size: clamp(14px,2vw,16px);
    color: var(--color);
    margin-right: -20px;
    margin-left: 20px;
    border-radius: 5px;
 }
 
 .export_btn:hover{
     background-color: rgba(211, 211, 211, 0.589);
     transition: all 0.4s ease-in-out;
 }
 
 .export_img{
     margin:0px 5px;
 }

@media (max-width:900px){
    .liveroom_wrapper{
        display: flex;
        flex-direction:column;
    }
   
    .liveRoom_wrapper_right{
        margin: 10px 0px;

    }
    .liveroom_iframe{
        width:950%;
        height: 600px;
    }
    .liveroom_table{
        width: 100%;
        border-collapse: collapse;
        margin-left: 0px;
    }
    .liveroom_table thead{
		display: none;
    }

     .liveroom_table td,.liveroom_table th{
        padding:12px 15px;
        border:1px solid #ddd;
        font-size:15px;
      }

	.liveroom_table, .liveroom_table tbody, .liveroom_table tr, .liveroom_table td{
		display: block;
		width: 100%;
    }

	.liveroom_table td{
		text-align: right;
		padding-left: 45%;
		text-align: right;
		position: relative;
    }
  
	.liveroom_table td::before{
		content: attr(data-label);
		position: absolute;
		left:0;
		width: 50%;
		padding-left:15px;
		font-size:14px;
		font-weight: 500;
        text-align: left;
      color: rgba(30, 83, 83, 0.856);
  }

      
    /* details_table css */
    .LiveroomDetails_wrapper{
        width: 95%;
    }

    .details_table thead{
        display: none;
    }
    
     .details_table td,.details_table th{
        padding:12px 15px;
        border:1px solid #ddd;
        font-size:15px;
      }
    
    .details_table, .details_table tbody, .details_table tr, .details_table td{
        display: block;
        width: 100%;
    }
    
    .details_table td{
        text-align: right;
        padding-left: 45%;
        text-align: right;
        position: relative;
    }
    
    .details_table td::before{
        content: attr(data-label);
        position: absolute;
        left:0;
        width: 50%;
        padding-left:15px;
        font-size:14px;
        font-weight: 600;
        text-align: left;
        color: rgba(30, 83, 83, 0.753);
    }
}

