.createVideo{
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: 'Roboto';
    margin: 20px 0px;
}

.createVideo_wrapper{
    width: 90vw;
    background-color:var(--containerBgColor);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    border-radius: 10px;
    box-shadow: var(--boxShadow); 
}

.createVideo_title{
    font-size: 25px;
    color: var(--color);
    margin: 20px 25px;
    text-align: center;
}

.createVideo_form{ 
    display: flex;
    align-items: center;
}

.createVideo_form1{ 
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 10px 10px;
}
.createVideo_form2{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 10px 10px;
}

.createVideo_input{
    width:40vw;
    height: 40px;
    padding-left: 20px;
    border: 1px solid #DFE1E8;
    border-radius: 20px;
    margin-bottom: 22px;
    outline: none;
}

.createVideo_textarea{
    width: 95%;
    height: 150px;
    border: var(--lightGreyBorder);
    outline: none;
    resize: none;
    border-radius: 10px;
    padding: 10px;
    margin: 0px 0px 10px 0px;
}

 .upload_btn{
    outline:0;
    border:0;
    padding:12px 22px;
    font-size:14px;
    border-radius: 50px;
    background: var(--blueBtn);
    color: white;
    cursor: pointer;
}

.upload_btn:hover{
    background: rgb(169, 170, 173);
    color: black;
    transition: all 0.4s ease-in-out;
}

.createVideo_btn_div{
    margin-top: 30px;
    margin-bottom:40px;
}

.createVideo_btn{
    outline: none;
    border: none;
    padding:15px 30px;
    border-radius: 100px;
    margin:10px 0px;
    color: white;
    background: var(--redBtn);
    cursor: pointer;
    font-size: 16px;
}

.createVideo_btn:hover{
    transition:all 0.3s ease-in-out;
    background-color:var(--redBtnHover);
 }
 
/*responsive*/

@media(max-width: 750px){

.createVideo_title{
    font-size: 18px;
}
.createVideo_form{ 
    width: 90%;
    display: flex;
    flex-direction: column;
}
.createVideo_form1{ 
    width: 100%;
}
.createVideo_form2{
    width: 100%;
}

.createVideo_input{
    width:100%;
    height: 40px;
    padding-left: 20px;
    border: 1px solid #DFE1E8;
    border-radius: 20px;
    margin-bottom: 30px;
}

.createVideo_textarea{
    width: 100%;
    height: 150px;
    margin:10 0px;
}
.upload_btn{
   margin-bottom: 10px;
}
   
}
@media screen and (max-width:420px){
    .createVideo_title{
        font-size: 16px;
    }
}
