.searchDate_div{
    display: flex;
    padding:5px;
    display: flex;
    align-items: center;
}

.searchDate_title{
color: var(--blueBtn);
font-size: 14px;
margin: 0px 10px 0px 0px;
}

.searchDate_input{
outline:0;
border:var(--lightGreyBorder);
font-weight: 600;
padding:10px 20px;
color:var(--errorColor);
border-radius: 55px;
}

.offlineRoom__input{
    width: 60%;
}

::-webkit-calendar-picker-indicator{
    transform: scale(1.2);
    transition: var(--transition);
}
::-webkit-calendar-picker-indicator:hover {
    cursor: pointer;
    transition: var(--transition);
    transform: scale(1.4);
  }
  

@media screen and (max-width:768px){
    .searchDate_div{
        display: flex;
        flex-direction: column;
        padding:5px;
        display: flex;
        align-items: center;
    }
    .searchDate_title{
        font-size: 13px;
        margin: 5px 0px;
        }

        .searchDate_input{
        padding:5px 18px;
        
        }

        .offlineRoom__input{
            width: 100%;
        }
}

@media screen and (max-width:360px){
    .searchDate_div{
        padding:0px;
    }
    .searchDate_title{
        font-size: 13px;
        margin: 5px 0px;
        }

        .searchDate_input{
        padding:5px 5px;
        width:70%
        }
}