.userlist{
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 80vh;
  font-family: 'Roboto';
  margin-top: 20px;
}

.userlist_wrapper{
  width: 90vw;
  background-color:var(--containerBgColor);
  display: flex;
  flex-direction: column;
  padding: 10px;
  border-radius: 10px;
  box-shadow:  var(--boxShadow);  
}

.userlist_title{
  font-size: 25px;
  color:var(--color);
  margin: 20px 25px;
}

.table{
width: 100%;
border-collapse: collapse;
}

.table td,.table th{
padding:15px 20px;
text-align: center;
font-size:15px;
border-bottom: 1px solid rgb(231, 226, 226);
}

.table th{
  font-size: 16px;
  font-weight: 500;
  color: #1e5353da;
}

.icons_table{
margin-right:10px;
display: flex;
align-items: center;
justify-content: center;
}

.userlist_editIcon{
color: var(--blueBtn);
font-size:24px;
cursor: pointer;
margin: 0px 10px;
}

.userlist_editIcon:hover{
transform: scale(1.1);
transition: all 0.4s ease;
}

.userlist_delete_icon{
color: var(--redBtn);
font-size:24px;
cursor: pointer;
margin-left:10px;
}

.userlist_delete_icon:hover{
  transform: scale(1.1);
  transition: all 0.4s ease;
}

.editDisableIcon{
  color: #808080b6;
font-size:24px;
 margin: 0px 10px;
}

.deleteDisableIcon{
color: #808080b6;
font-size:24px;
margin-left:10px;
}

/*responsive*/

@media(max-width: 750px){

.userlist_title{
  font-size: 18px;
  margin: 20px 25px;
}
.table thead{
  display: none;
  }

   .table td,.table th{
      padding:12px 15px;
      border:1px solid #ddd;
      font-size:15px;
    }

.table, .table tbody, .table tr, .table td{
  display: block;
  width: 100%;
  }
  
.table tr{
  margin-bottom:15px;
}
  
.table td{
  text-align: right;
  padding-left: 45%;
  text-align: right;
  position: relative;
}

.table td::before{
  content: attr(data-label);
  position: absolute;
  left:0;
  width: 50%;
  padding-left:15px;
  font-size:14px;
  font-weight: 500;
  text-align: left;
  color: rgba(30, 83, 83, 0.856);
}

.icons_table{
  margin-right:10px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

}
