.contactAdmin{
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: 'Roboto';
    margin-top: 20px;
    height: 75vh;
}

.contactAdmin_wrapper{
    width: 90vw;
    background-color: var(--containerBgColor);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    border-radius: 10px;
    box-shadow: var(--boxShadow); 
}

.contactAdmin_title{
    font-size: 25px;
    color:var(--color);
    margin: 20px;
    text-align: center;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content:center;
}

 .back_icon_centerAlign{
        color: var(--color);
        font-size: 40px;
        margin: 6px 25px 0px -45px;
}
      
 .back_icon_centerAlign:hover{
        transition: 0.3s all ease-in-out;
        transform: scale(1.1);
 }

.contactAdmin_form{ 
    display: flex;
    flex-direction: column;
    align-items: center;
}

.contactAdmin_input{
    width:40vw;
    height: 40px;
    padding-left: 20px;
    border: 1px solid #DFE1E8;
    border-radius: 20px;
    margin-bottom: 30px;
    outline: none;
}

.contactAdmin_textarea{
    width: 100%;
    height: 150px;
    margin:0 10px;
    border: var(--lightGreyBorder);
    outline: none;
    resize: none;
    border-radius: 5px;
    padding: 10px 10px;
}

.contactAdmin_btn_div{
    margin-top: 30px;
    margin-bottom:40px;
}

/*responsive*/

@media(max-width: 750px){

.contactAdmin_title{
    font-size: 18px;
}
.back_icon_centerAlign{
    font-size: 35px;
}
.contactAdmin_form{ 
    width: 80%;
}

.contactAdmin_input{
    width:100%;
    height: 40px;
    padding-left: 20px;
    border-radius: 20px;
    margin-bottom: 30px;
    outline: none;
}

.contactAdmin_textarea{
    width: 100%;
    height: 150px;
    margin:0 10px;
    outline: none;
    resize: none;
    border-radius: 5px;
    padding: 10px 10px;
}
   
}
