.videolist{
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: 'Roboto';
    margin-top: 20px;
}

.video_wrapper{
  width: 90vw;
  background-color: var(--containerBgColor);
  display: flex;
  flex-direction: column;
  align-items:center;
  padding: 10px;
  border-radius: 10px;
  box-shadow: var(--boxShadow);  
}


.video_shortName{
 color: var(--blueBtn);
 cursor: pointer;
}

 .video_select{
    display: flex;
    justify-content: center;
    align-items: center;
}

.Selectvideo_input{
  width:35vw;
  height: 40px;
  padding-left: 15px;
  border: 1px solid #DFE1E8;
  border-radius: 20px;
  margin: 0px 15px 15px 5px;
  margin-bottom: 25px;
  outline: none;
}


.video_title{
    font-size: 25px;
    color: var(--color);
    margin: 20px 25px;
    text-align: center;
}

.video_table{
	width: 100%;
	border-collapse: collapse;
}

.video_table td,.video_table th{
  padding:15px 20px;
  text-align: center;
  font-size:15px;
  border-bottom: 1px solid #e7e2e2;
}

.video_table th{
    font-size: 16px;
    font-weight: 500;
    color: rgba(30, 83, 83, 0.856);
}


/*responsive*/

@media(max-width: 750px){

.video_title{
    font-size: 18px;
    margin: 20px 25px;
}

.video_select{
  display: flex;
  flex-direction: column;
  width: 100%;
}

.Selectvideo_input{
  width: 90%;
  margin: 0px 0px;
  margin-bottom: 25px;
  outline: none;
}


.video_table thead{
		display: none;
  }

  .video_table td,.video_table th{
        padding:12px 15px;
        border:1px solid #ddd;
        font-size:15px;
   }

	.video_table, .video_table tbody, .video_table tr, .video_table td{
		display: block;
		width: 100%;
    }
    
	.video_table tr{
		margin-bottom:15px;
  }
    
	.video_table td{
		text-align: right;
		padding-left: 45%;
		text-align: right;
		position: relative;
  }
  
	.video_table td::before{
		content: attr(data-label);
		position: absolute;
		left:0;
		width: 50%;
		padding-left:15px;
		font-size:14px;
		font-weight: 500;
    text-align: left;
    color: rgba(30, 83, 83, 0.856);
  }


}

@media screen and (max-width:420px){
  .video_title{
      font-size: 16px;
  }
  .Selectvideo_input{
    width: 100%;
  }
}
