.openingQuestion_form{ 
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90%;
}

.openingQuestion_textarea{
    width: 50%;
    height: 120px;
    border: var(--lightGreyBorder);
    outline: none;
    resize: none;
    border-radius: 10px;
    padding: 10px;
    margin: 0px 0px 20px 0px;
}

.openingQuestion_media{ 
    display: flex;
    justify-content: center;
    align-items: center;
    width: 90%;
}

.openingQuestion_Mediainput{
    width:30vw;
    height: 40px;
    padding-left: 20px;
    border: 1px solid #DFE1E8;
    border-radius: 20px;
    margin: 10px 20px;
    outline: none;
}

.openingQuestion_input{
    width:18vw;
    height: 40px;
    padding-left: 20px;
    border: 1px solid #DFE1E8;
    border-radius: 20px;
    margin: 15px;
    outline: none;
}

.openingQuestion_ChoiceForm{
    display: flex;
    margin:40px 0px;
    width: 100%;
}

.openingQuestion_ChoiceForm1{ 
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 10px 0px;
    width: 50%;
}
.openingQuestion_ChoiceForm2{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 10px 0px;
    width: 50%;
}

.openingQuestion_ChoiceField{
    display: flex;
}
    
.identity_div > p,
.choice_div > p {
         text-align:left;
         margin: 0px 0px -10px 30px;
         font-size:12px;
         font-weight: 700;
         color: var(--color);
 }

.choice_div > p {
font-size: clamp(11px , 2vw , 12px);
}

.openingQuestion__checkbox-wrapper{
    border: 1px solid #eeeeee;
    padding: 15px 10px;
    margin: 10px ;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
    border-radius:5px;
}
.openingQuestion__checkbox-wrapper > input {
    margin: 0px 15px;
}

.openingQues__delIcon{
    font-size: 30px;
    color: var(--errorColor);
    margin:0px -50px 0px 20px;
}

.openingQues__delIcon:hover{
    transform: scale(1.1);
    transition: var(--transition);
    cursor: pointer;
}

 
/*responsive*/

@media(max-width: 750px){

.createVideo_title{
    font-size: 18px;
}
.openingQuestion_form{ 
    width: 90%;
    display: flex;
    flex-direction: column;
}

.openingQuestion_textarea{
    width: 100%;
    height: 120px;
}

.openingQuestion_media{ 
    display: flex;
    flex-direction:column;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.openingQuestion_ChoiceForm{ 
    width: 100%;
    display: flex;
    flex-direction: column;
}
.openingQuestion_ChoiceForm1{ 
    width: 100%;
    margin:0px;
}
.openingQuestion_ChoiceForm2{
    width: 100%;
    margin:0px;
}
.openingQuestion_Mediainput{
    width:100%;
    margin: 20px;
}
.choice_div{
    width: 50%;
    
}
.identity_div{
    width: 50%; 
}

.openingQuestion_input{
    width:90%;
    height: 40px;
    margin: 20px 10px 20px 10px;
}
.openingQuestion_ChoiceField{
    width:90%;
    display: flex;
}

.openingQuestion__checkbox-wrapper{
    width: 90%;
}
   
}

@media screen and (max-width:420px){
    .createVideo_title{
        font-size: 16px;
    }
    .openingQuestion_ChoiceForm{
        margin:30px 0px 10px 0px;
    }

    .openingQuestion_ChoiceField{
        display: flex;
        flex-direction: column;
        width:100%;
    }

    .choice_div{
        width: 100%;
        
    }
    .identity_div{
        width: 100%; 
    }
}
