.admin_header{
    height:76px;
    color:#fff;
    width: 100%;
    background: linear-gradient(180deg, #123075 0%, #133a97 100%);
    display:flex;
    align-items:center;
    justify-content: center;
}

.admin_menu_bars{
    position: absolute;
    left: 80px;
    margin-top:6px;
}

.menu_icon{
 font-size: 24px;
 color:#fff;
 cursor: pointer;
}

.adminSidebar_menu{
    position:absolute;
    left:-150%;
    top: 76px;
    background: #fff;   
    color:black;
    border-radius:0px 3px 3px 0px;  
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 88vh;
    width: 280px;
    z-index: 3;
    transition: all 0.8s ease-in-out;
    box-shadow:10px 10px 10px -6px rgba(0, 0, 0, 0.678); 
}

.admin_sidebar_menu_active{
    position:absolute;
    left:0px;
    top: 76px;
    background: #fff;     
    color:black;      
    border-radius:0px 3px 3px 0px;
     display: flex;
    align-items: center;
    flex-direction: column;
    height: 88vh;
    width: 280px;
    z-index: 3;
    transition: all 0.4s ease;
    box-shadow:10px 10px 10px -6px rgba(0, 0, 0, 0.288);
}


.admin_header_title > h4{
    font-size: 30px;
    font-weight: 500;
}

    /* sidebar */

.sidebar_menu_ul{
    padding:5px;
    padding-top: 15px;
    width: 80%;
    height:70%;
    list-style-type: none;
    margin-bottom:10px;
}

.sidebar_svg{
    margin: 0px 7px;
    font-size:32px;
    color: rgb(29, 28, 28);
}

.admin_sidebar_links{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 10px 0px;
    padding:10px;
    text-decoration: none;
    color: black;

}

.sidebar_menu_title{
    font-size: 18px;
    color: rgb(0, 0, 0);
    margin-left:5px;
}

.sidebar_menu_title:hover{
  color: rgb(23, 23, 240);
  transition: all 0.3s ease-in-out;
}

.sidebar_logout_btn{
    outline:0;
    border: 0;
    height: 60px;
    width: 50%;
    color: white;
    background: var(--redBtn);
    border-radius:105px;
    cursor: pointer;
    font-size: 18px;
    margin-top: 20px;
}

.sidebar_logout_btn:hover{
    background-color:var(--redBtnHover);
    transition:all 0.4s ease-in-out;
}

.admin_header_btn{
    position: absolute;
    right: 15px;
}

.btn{
    border: 2px solid white;
    border-radius: 5px;
    outline:0;
    padding: 9px 18px;
    margin: 0px 10px;
    background: transparent;
    cursor: pointer;
    color:white;
    transition:var(--transition);
}

.btn:hover{
   background:white;
   color:black;
   padding: 9px 18px;
   transition:var(--transition);
}


   /* Responsive Mobile-View */

@media screen and (max-width:750px ){
    .admin_header_title > h4{
        font-size: 25px;
    }
    .admin_menu_bars{
        position: absolute;
        left: 34px;
    }

    .admin_header{
        height:73px;
    }

    .adminSidebar_menu{
        left:-150%;
        top: 80px;
        height: 87vh;
        width: 250px;
    }
    
    .admin_sidebar_menu_active{
        left:0px;
        top: 80px;
        height:87vh;
        width: 240px;
    }

    .sidebar_menu_title{
        font-size: 17px;
        color: rgb(0, 0, 0);
        margin-left:4px;
    }

    .admin_header_btn{
        position: absolute;
        right: 10px;
    }
    .sidebar_logout_btn{
        height: 60px;
        width: 55%;
        font-size: 16px;
        margin-top: 10px;
    }
    

    .btn{
        border: 2px solid white;
        border-radius: 5px;
        outline:0;
        padding: 4px 8px;
        font-size: 10px;
        margin: 0px 5px;
        background: transparent;
        cursor: pointer;
        color:white;
        text-align: center;
    }
    .btn:hover{
       background:white;
       color:black;
       padding: 4px 8px;
       transition: all 0.4s ease-in-out;
    }
    
}
   
@media screen and (max-width:450px){
    .adminSidebar_menu{
        left:-150%;
        top: 83px;
        height: 88%;
        width: 250px; 
    }
    
    .admin_sidebar_menu_active{
        left:0px;
        top: 83px;
        height: 88%;
        width: 240px;
    }

    .sidebar_menu_ul{
        padding:5px;
        padding-top: 30px;
        width: 80%;
        height:66%;
        list-style-type: none;
        /* border: 1px solid black; */
    }
    
    
    .admin_sidebar_links{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin: 10px 0px;
        padding:10px;
        text-decoration: none;
        color: black;
    }
    
    .sidebar_menu_title{
        font-size: 18px;
        margin-left:5px;
    }
    
    .sidebar_logout_btn{
        height: 60px;
        width: 55%;
        color: white;
        font-size: 18px;
        margin-top: 50px;
    }

    .admin_header_title > h4{
        margin-left: -15px;
    }

}

@media screen and (max-width:360px){
    .adminSidebar_menu{
        left:-150%;
        top: 84px;
        height: 86%;
        width: 250px;
    }
    
    .admin_sidebar_menu_active{
        left:0px;
        top: 84px;
        height: 86%;
        width: 240px;
    }

    .sidebar_menu_ul{
        padding:5px;
        padding-top: 10px;
        margin-bottom: 0px;
        width: 85%;
        height:63vh;
        list-style-type: none;
    }
    
    .admin_sidebar_links{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin: 10px 0px;
        padding:5px;
        text-decoration: none;
        color: black;

    }
    
    .sidebar_menu_title{
        font-size: 18px;
        color: rgb(0, 0, 0);
        margin-left:5px;
    }
    
    
    .sidebar_logout_btn{
        height: 55px;
        width: 55%;
        margin-top: 0px;
    }

}


@media screen and (max-width:330px ){

    .sidebar_menu_ul{
        padding:5px;
        padding-top: 10px;
        margin-bottom: 0px;
        width: 75%;
        height:60vh;
        list-style-type: none;
    }
    
    .sidebar_svg{
        font-size:25px;
    }
    
    .admin_sidebar_links{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin: 10px 0px;
        padding:5px;
        text-decoration: none;
    }

    .sidebar_menu_title{
        font-size: 16px;
        margin-left:5px;
    }
    
    
    .sidebar_logout_btn{
        height: 50px;
        width: 50%;
    }

    .btn{
        border: 2px solid white;
        border-radius: 5px;
        outline:0;
        padding: 3px 6px;
        font-size: 8px;
        margin: 0px 4px;
        background: transparent;
        cursor: pointer;
        color:white;
        text-align: center;
    }
    .btn:hover{
       background:white;
       color:black;
       padding: 3px 6px;
       transition: all 0.4s ease-in-out;
    }
}


