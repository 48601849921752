.updateQuestion_btn{
    outline: none;
    border: none;
    padding:15px 30px;
    border-radius: 100px;
    margin:10px 0px;
    color: white;
    background:var(--redBtn);
    cursor: pointer;
    font-size: 16px;
}

.updateQuestion_btn:hover{
    transition:all 0.4s ease-in-out;
    background-color:var(--redBtnHover);
}