.userlist{
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 80vh;
    font-family: 'Roboto';
    margin-top: 20px;
}

.userlist_wrapper{
    width: 90vw;
    background-color: var(--containerBgColor);
    display: flex;
    flex-direction: column;
    padding: 10px;
    border-radius: 10px;
    box-shadow: var(--boxShadow);  
  }

.userlist_title{
    font-size: 25px;
    color: var(--color);
    margin: 12px 25px 8px 25px;
    display: flex;
    align-items: center;
}

.back_icon{
  color: var(--color);
  font-size: 40px;
  margin: 6px 25px 0px 5px;
}

.back_icon:hover{
  transition: 0.3s all ease-in-out;
  transform: scale(1.1);
}

.table{
	width: 100%;
	border-collapse: collapse;
}

.table td,.table th{
  padding:15px 20px;
  text-align: center;
  font-size:15px;
  border-bottom: 1px solid rgb(231, 226, 226);
}

.table th{
    font-size: 16px;
    font-weight: 500;
    color: #1e5353da;
}

.icons_table{
  margin-right:10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.editIcon{
  color: var(--blueBtn);
  font-size:24px;
  cursor: pointer;
  margin: 0px 10px;
}

.editIcon:hover{
  transform: scale(1.1);
  transition: all 0.4s ease;
}

.deleteIcon{
 color: var(--redBtn);
 font-size:24px;
 cursor: pointer;
 margin-left:10px;
}

.deleteIcon:hover{
    transform: scale(1.1);
    transition: all 0.4s ease;
}

/* pagination*/
.pagination_wrapper{
  width: 100%;
  padding: 5px;
  margin: 25px 0px;
  display: flex;
  justify-content: center;
 }
 
 .pagination_btn{
   width: 90%;
   height: 40px;
   list-style-type: none;
   display: flex;
   justify-content: center;
   align-items: center;
 }

 .pagination_btn a {
  padding:8px 10px;
  margin: 8px;
  border-radius: 5px;
  font-size:12px;
  background-color: rgb(236, 231, 231);
  color: #1180ac ;
  cursor: pointer;
  outline:none;
  border: none;
 }

 .pagination_btn a:hover{
   color: white;
   background-color:#1180ac;
   transition:all 0.3s ease-in-out;
 }
 
 .pagination_btn_active a{
   color: white;
   background-color:#1180ac;
   outline:none;
   border: none;
 }

/*responsive*/

@media(max-width: 750px){

  .userlist_title{
    font-size: 18px;
    margin: 20px 10px;
}
.back_icon{
  font-size: 35px;
  margin: 6px 10px 0px 0px;
}
	.table thead{
		display: none;
    }

     .table td,.table th{
        padding:12px 15px;
        border:1px solid #ddd;
        font-size:15px;
      }

	.table, .table tbody, .table tr, .table td{
		display: block;
		width: 100%;
    }
    
	.table tr{
		margin-bottom:15px;
  }
    
	.table td{
		text-align: right;
		padding-left: 45%;
		text-align: right;
		position: relative;
  }
  
	.table td::before{
		content: attr(data-label);
		position: absolute;
		left:0;
		width: 50%;
		padding-left:15px;
		font-size:14px;
		font-weight: 500;
    text-align: left;
    color: #1e5353da;
  }

  .icons_table{
    margin-right:10px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

    /* pagination*/
  .pagination_wrapper{
    width: 100%;
    padding: 0px;
    margin: 0px;
    display: flex;
    justify-content: center;
   }
   
   .pagination_btn{
     width: 90%;
     height: 40px;
     list-style-type: none;
     display: flex;
     justify-content: center;
     align-items: center;
     margin-right: 10px;
   }
   
   .pagination_btn a {
    padding:8px 9px;
    margin: 6px;
    border-radius: 5px;
    font-size:13px;
    background-color: rgb(236, 231, 231);
    cursor: pointer;
   }

   .pagination_btn_active a{
    color: white;
    background-color:#1180ac;
    outline:none;
    border: none;
  }
}

@media screen and (max-width: 420px){
  .userlist_title{
    font-size: 15px;
   }
  /* pagination*/
  .pagination_wrapper{
    width: 100%;
    padding: 0px;
    margin: 0px;
    display: flex;
    justify-content: center;
   }
   
   .pagination_btn{
     width: 90%;
     height: 40px;
     list-style-type: none;
     display: flex;
     justify-content: center;
     align-items: center;
     margin-right: 20px;
   }
   
   .pagination_btn a {
    padding:8px 7px;
    margin: 5px;
    border-radius: 5px;
    font-size:10px;
    background-color: rgb(236, 231, 231);
    cursor: pointer;
   }

   .pagination_btn_active a{
    color: white;
   background-color:#1180ac;
    outline:none;
    border: none;
  }

   
   .table td,.table th{
    padding:12px 15px;
    border:1px solid #ddd;
    font-size:13px;
  }
}