.gamelist{
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: 'Roboto';
    margin-top: 20px;
}

.gamelist_wrapper{
    width: 90vw;
    background-color:var(--containerBgColor);
    display: flex;
    flex-direction: column;
    padding: 10px;
    align-items: center;
    border-radius: 10px;
    box-shadow: var(--boxShadow);  
}

.game_title{
    font-size: 25px;
    color: var(--color);
    margin: 20px 25px;
    text-align: center;
}

.game_table{
	width: 100%;
	border-collapse: collapse;
}

.game_table td,.game_table th{
  padding:15px 20px;
  text-align: center;
  font-size:15px;
  border-bottom: 1px solid rgb(231, 226, 226);
}

.game_table th{
    font-size: 14px;
    font-weight: 500;
    color: rgba(30, 83, 83, 0.856);
}


/*responsive*/

@media(max-width: 750px){

  .game_title{
    font-size: 18px;
    margin: 20px 25px;
}
.game_table thead{
		display: none;
}

    .game_table td,.game_table th{
        padding:12px 15px;
        border:1px solid #ddd;
        font-size:15px;
      }

	.game_table, .game_table tbody, .game_table tr, .game_table td{
		display: block;
		width: 100%;
    }
    
	.game_table tr{
		margin-bottom:15px;
  }
    
	.game_table td{
		text-align: right;
		padding-left: 45%;
		text-align: right;
		position: relative;
  }
  
	.game_table td::before{
		content: attr(data-label);
		position: absolute;
		left:0;
		width: 50%;
		padding-left:15px;
		font-size:14px;
		font-weight: 500;
    text-align: left;
    color: rgba(30, 83, 83, 0.856);
  }
   
}

@media screen and (max-width:420px){
  .game_title{
      font-size: 16px;
  }
}
