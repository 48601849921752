
.reset__main{
    display: flex;
    height: 80vh;
    z-index: 3;
    margin-bottom: 200px;
    margin-top: 100px;
}

@media  screen and (max-width: 750px) {
    .reset__main{
        display:flex;
        flex-direction: column;
        margin-bottom: 100px;
        margin-top: 50px;
    }
}