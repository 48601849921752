
.tab_container{
 display: flex;
 flex-direction: column;
 align-items:center;
}

.tab_wrapper{
    width: 75%;
}

/* .tabs_mainTitle {
    font-size:clmap(18px,5vw,25px);
    color:rgba(41, 41, 43, 0.644);
} */

.app_bar{
    margin: 20px 0px;
    border-radius: 12px;
}

.tabs{
    display: flex;
    align-items:center;
    height: 60px;
    background-color: #123075a9;
    border-radius: 12px;
    padding:35px;
}

.tab_btn_div{
    margin:12px 0px;
}

.tab_btn{
    outline: none;
    border: none;
    padding:14px 55px;
    border-radius: 100px;
    margin:10px 0px;
    color: white;
    background: #C31818;
    cursor: pointer;
    font-size: 16px;
}
  .tab_btn:hover{
    padding:14px 55px;
    transition:all 0.4s ease-in-out;
    background-color:rgb(46, 44, 44);
 }


@media screen and (max-width:750px){
    .tab_wrapper{
        width: 95%;
    }

    .tab_title{
        font-size:11px !important;
    }

    .tabs{
        display: flex;
        align-items:center;
        height: 65px;
        background-color: #123075a9;
        border-radius: 8px;
        padding:10px;
    }
}
