.modal_wrapper{
    display:flex;
    flex-direction:column;
    align-items:center;
    padding:10px;
}

.createGroup_ModalTitle{
    color: rgb(255, 255, 255);
    margin-bottom:20px;
}

.modal_btns{
    display:flex;
    margin-top:15px;
}
.modal_red_btn{
   padding: 10px 30px;
   margin: 0px 10px;
   background-color: var(--redBtn);
   border:0;
   outline:0;
   border-radius: 25px;
   color:white;
}
.modal_blue_btn{
    padding: 10px 30px;
    margin: 0px 10px;
    background-color: var(--blueBtn);
   border:0;
   outline:0;
   border-radius: 25px;
   color:white;
}

.modal_red_btn:hover{
    cursor: pointer;
    background-color:var(--redBtnHover);
    transition:all 0.4s ease-in-out;
}
.modal_blue_btn:hover{
    cursor: pointer;
    background-color:var(--redBtnHover);
    transition:all 0.4s ease-in-out;
}

@media screen and (max-width: 750px){
    .modal_wrapper{
        display:flex;
        flex-direction:column;
        align-items:center;
        padding:0px;
    }
    .createGroup_ModalTitle{
        font-size:12px;
    }
    
    .modal_wrapper > h3{
        margin-bottom:20px;
        font-size:13px;
    }
    
    .modal_btns{
        display:flex;
        margin-top:5px;
    }
    .modal_red_btn{
       padding: 8px 16px;
       margin: 0px 10px;
       border:0;
       outline:0;
       border-radius: 25px;
       color:white;
       font-size:12px;
    }
    .modal_blue_btn{
        padding: 8px 16px;
        margin: 0px 10px;
       border:0;
       outline:0;
       border-radius: 25px;
       color:white;
       font-size:12px;
    }
    
}