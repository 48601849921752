.manageGame{
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: 'Roboto';
    margin-top: 20px;
}

.manageGame_wrapper{
    width: 90vw;
    background-color: var(--containerBgColor);
    display: flex;
    flex-direction: column;
    padding: 10px;
    border-radius: 10px;
    box-shadow: var(--boxShadow); 
    margin-bottom:40px 
}

.manageGame_titleWrapper{
    display: flex;
    margin-right: 20px;
    justify-content: space-between;
    align-items: center;
    padding: 6px ;
    padding-right: 25px;
}

.manageGame_title{
    font-size: 25px;
    color: var(--color);
}

.create_btn {
    outline:0;
    border:0;
    padding:12px 22px;
    font-size:14px;
    border-radius: 50px;
    color: white;
    background: var(--redBtn);
    cursor: pointer;
}

.create_btn:hover{
    background-color:var(--redBtnHover);
    transition: all 0.4s ease-in-out;
}

/*responsive*/

@media(max-width: 750px){
  .manageGame_titleWrapper{
        margin-right: 10px;
        padding: 5px;
  }

  .manageGame_title{
    font-size: 18px;
  }
  .create_btn {
    padding:8px 8px;
    font-size:11px;  
}
   
}
