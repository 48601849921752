:root {
    --primary: #F91D3E;
}

.Header{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    height: 100px;
    background-color: #123075;
    border-bottom: 0.1px rgba(112,155,231, 0.3) solid;
}

.logo{
    color: #FFFFFF;
    font-size: 34px;
    font-weight: 500;
    margin-left: 4vw;
}

.Header__right{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-left: 20vw;
}


.Header__icon{
    background-color: var(--primary);
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.3);
    margin-left: 10px;
}

.sidebar_menu{
    position:absolute;
    left:-150%;
    top: 105px;
    background: #fff;   
    color:black;
    border-radius:0px 3px 3px 0px;  
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 83vh;
    width: 280px;
    z-index: 3;
    transition: all 0.8s ease-in-out;
    box-shadow:10px 10px 10px -6px rgba(0, 0, 0, 0.678); 
}

.sidebar_menu_active{
    position:absolute;
    left:0px;
    top: 105px;
    background: #fff;     
    color:black;      
    border-radius:0px 3px 3px 0px;
    display: flex;
    align-items: center;
    flex-direction: column;
    height: 83vh;
    width: 280px;
    z-index: 3;
    transition: all 0.4s ease;
    box-shadow:10px 10px 10px -6px rgba(0, 0, 0, 0.288);
}


   /* Responsive Mobile-View */

   @media screen and (max-width:750px ){

    .sidebar_menu{
        left:-150%;
        top: 105px;
        height: 83vh;
        width: 250px;
    }
    
    .sidebar_menu_active{
        left:0px;
        top: 105px;
        height:83vh;
        width: 240px;
    }
    
}

@media screen and (max-width:600px ){
    .logo{
        color: #FFFFFF;
        font-size: 25px;
        font-weight: 400;
        margin-left: 12vw;
        }
   
       .Header__right{
           display: flex;
           flex-direction: row;
           justify-content: space-between;
           align-items: center;
           margin-left: 15vw;
       }
  }
   
@media screen and (max-width:450px){
    .sidebar_menu{
        left:-150%;
        top: 110px;
        height:83vh;
        width: 250px;
        z-index: 3;
    }
    .sidebar_menu_active{ 
        left:0px;
        top: 110px;
        height:83vh;
        width: 240px;
        z-index: 3;
    }
   
       .Header__right{
           display: flex;
           flex-direction: row;
           justify-content: space-between;
           align-items: center;
           margin-left: 0;
     }

     .logo{
        font-size: 22px;
        margin-left: 4vw;
      }
      

}


@media screen and (max-width:360px){
    .sidebar_menu{
        left:-150%;
        top: 110px;
        height: 80vh;
        width: 240px;
        z-index: 3;
    }
    
    .sidebar_menu_active{
        left:0px;
        top: 110px;
        height: 80vh;
        width: 240px;
        z-index: 3;
    }

}





