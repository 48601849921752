.manageVideo{
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: 'Roboto';
    margin-top: 20px;
}

.manageVideo_wrapper{
    width: 90vw;
    background-color: var(--containerBgColor);
    display: flex;
    flex-direction: column;
    padding: 10px;
    border-radius: 10px;
    box-shadow: var(--boxShadow); 
    margin-bottom:40px 
}

.manageVideo_titleWrapper{
    display: flex;
    margin-right: 20px;
    justify-content: space-between;
    align-items: center;
    padding: 6px ;
    padding-right: 25px;
}

.manageVideo_title{
    font-size: 25px;
    color: var(--color);
}

.create_btn {
    outline:0;
    border:0;
    padding:12px 22px;
    font-size:14px;
    border-radius: 50px;
    color: white;
    background: var(--redBtn);
    cursor: pointer;
}

.create_btn:hover{
    background-color:var(--redBtnHover);
    transition: all 0.4s ease-in-out;
}

.video_iframe{
    width:400px;
    height:225px;
    margin-top: 10px;
    border-radius:10px;
    background: linear-gradient(to left, rgb(170, 167, 167), rgba(223, 83, 83, 0.774)); 
    padding: 5px;
    object-fit:cover;
    outline:0;
    transition: var(--transition);
    animation: scaleAnimate .6s ease-in-out;
}

@keyframes scaleAnimate{
    from {
         opacity:0;
         transform:scale(0.5);
        }
    to{ 
        opacity:1;
        transform: scale(1)
    }
}

/*responsive*/

@media(max-width: 750px){
  .manageVideo_titleWrapper{
        margin-right: 20px;
        padding: 0px;
  }

  .manageVideo_title{
    font-size: 18px;
  }
  .create_btn {
    padding:10px 20px;
    font-size:12px;  
}

.video_iframe{
    margin-left:-400px;
    width:350px;
    height:250px;
}
   
}

@media(max-width: 430px){
    .video_iframe{
        width:280px;
        height:200px;
    }
}

@media(max-width: 350px){
    .video_iframe{
        width:200px;
        height:160px;
    }
}